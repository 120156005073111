import * as Types from '../types';

const initialState = {
  title: null,
  descriptions: null,
  video: null,
  number: null,
  errors: null,
};

export const InstructorLecture = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.INS_LECTURE_UPLOAD_SUCCESS: {
      return {
        ...state,
        lecture: payload,
        errors: null,
      };
    }
    case Types.INS_LECTURE_UPLOAD_FAILED: {
      return {
        ...state,
        errors: payload.errors,
      };
    }

    default: {
      return state;
    }
  }
};

import { Redirect } from "react-router-dom";
import { systemStatus } from "../helpers/auth.status";
import { DefaultRouter } from "./route";
export const PrivateRoute = (props) => {
  if (systemStatus.getAuthStatus()) {
    return (
      <DefaultRouter exact path={props.path} component={props.component} />
    );
  } else {
    return <Redirect to="/login" />;
  }
};

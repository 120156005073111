import { Redirect } from "react-router-dom";
import { systemStatus } from "../helpers/auth.status";
import { DefaultRouter } from "./route";

export const Guest = (props) => {
  return <DefaultRouter exact path={props.path} component={props.component} />;
};

export const NonAuthenticatedRoute = (props) => {
  if (systemStatus.getAuthStatus()) {
    return <Redirect to={`/home`} />;
  } else {
    return (
      <DefaultRouter exact path={props.path} component={props.component} />
    );
  }
};

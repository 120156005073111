import * as Types from '../types';

const initialState = {
  courseUpdate: null,
  errors: null,
  course: null
};

export const Instructor = (state = initialState, { type, payload }) => {
  switch (type) {
    case Types.INS_COURSE_UPLOAD_SUCCESS: {
      return {
        ...state,
        course: payload,
        errors: null,
      };
    }

    case Types.CLEAN_UPLOADED_COURSE:{
      return {
        ...state,
        errors:null,
        course: null
      }
    }
    case Types.INS_COURSE_UPLOAD_FAILED: {
      return {
        ...state,
        errors: payload.errors,
      };
    }
    case Types.INS_COURSE_UPDATE_SUCCESS: {
      return {
        ...state,
        courseUpdate: payload,
        errors: null,
      };
    }
    case Types.INS_COURSE_UPDATE_FAILED: {
      return {
        ...state,
        errors: payload.errors,
      };
    }
    default: {
      return state;
    }
  }
};

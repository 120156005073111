import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Trans } from "react-i18next";
import arrowDown from "../../assets/svgs/chevron-down.svg";
import { useEffect, useState } from "react";
function Sidebar() {
  const [courseTab, toggleCourseTab] = useState(false);

  const url = useLocation();

  const getPathName = (expectedPath: string) => {
    if (url.pathname.includes(expectedPath)) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (getPathName("my-courses") || getPathName("subscribers")) {
      toggleCourseTab(true);
    }
  }, []);

  return (
    <>
      <nav id="sidebar">
        <div className="links">
          <ul className="list-unstyled components">
            <li
              className={`side  ${
                getPathName("create-course") ? "side-active" : ""
              } m-3`}
            >
              <Link to="/dashboard/create-course">
                <span style={{ fontSize: 20 }}>
                  {" "}
                  <i className="fad fa-plus"></i> <Trans>create-course</Trans>
                </span>
              </Link>
            </li>
            <li
              className={`side  ${
                getPathName("my-courses") || getPathName("subscribers")
                  ? "side-active"
                  : ""
              } m-3`}
            >
              <Link to="#" onClick={() => toggleCourseTab(!courseTab)}>
                <div>
                  <span style={{ fontSize: 20 }}>
                    {" "}
                    <i className="fad fa-paste"></i> <Trans>my-courses</Trans>
                  </span>
                </div>

                <div>
                  {courseTab ? (
                    <img
                      src={arrowDown}
                      style={{ transform: "rotateX(190deg)" }}
                      alt=""
                    />
                  ) : (
                    <img src={arrowDown} alt="" />
                  )}
                </div>
              </Link>
            </li>
            {courseTab ? (
              <li
                className={`side  ${
                  getPathName("my-courses") ? "side-active" : ""
                } m-3`}
              >
                <Link to="/dashboard/my-courses">
                  <div>
                    <span style={{ fontSize: 20 }}>
                      <i className="fa fa-arrow-right"></i>{" "}
                      <Trans>my-courses</Trans>
                    </span>
                  </div>
                </Link>
              </li>
            ) : null}
            {courseTab ? (
              <li
                className={`side  ${
                  getPathName("subscribers") ? "side-active" : ""
                } m-3`}
              >
                <Link to="/dashboard/subscribers">
                  <div>
                    <span style={{ fontSize: 20 }}>
                      <i className="fa fa-arrow-right"></i>{" "}
                      <Trans>subscribers</Trans>
                    </span>
                  </div>
                </Link>
              </li>
            ) : null}

            <li
              className={`side  ${
                getPathName("earns-incomes") ? "side-active" : ""
              } m-3`}
            >
              <Link to="/dashboard/earns-incomes">
                <div>
                  <span style={{ fontSize: 20 }}>
                    <i className="fa fa-dollar-sign"></i>{" "}
                    <Trans>incoming</Trans>
                  </span>
                </div>
              </Link>
            </li>
            {/* 
            {!instituteMember && (
              <li>
                <Link to={`/dashboard/course-earns`}>
                  <span style={{ fontSize: 20 }}>
                    <i className="fab fa-bitcoin"></i> <Trans>earn</Trans>
                  </span>
                </Link>
              </li>
            )}
            <li>
              <Link to="/dashboard/income-details">
                <span style={{ fontSize: 20 }}>
                  {" "}
                  <i className="fas fa-money-check"></i> <Trans>income</Trans>
                </span>
              </Link>
            </li>{" "} */}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Sidebar;

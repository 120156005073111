import Sidebar from "./Sidebar";

import "./dashboard.css";
import { useEffect } from "react";
const Layout = (props) => {
  useEffect(() => {
    document.querySelector("footer")?.classList?.add("hidden");
    return () => {
      document.querySelector("footer")?.classList?.remove("hidden");
    };
  }, []);
  return (
    <div>
      <div className="wrapper">
        <Sidebar />
        <div className="main">
          <div className="dashboard-content">{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;

import { GlobalServiceAdapter } from "../helpers/services";

const GET_CATEGORIES = "CATEGORIES/GET_CATEGORIES";

export default function (state = {}, { type, payload }) {
  switch (type) {
    case GET_CATEGORIES: {
      return { ...state, loading: false, categories: payload };
    }
    default: {
      return state;
    }
  }
}

const getCategories = () => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapter.fetchData(
      `courses/categories/main`
    );

    dispatch({ type: GET_CATEGORIES, payload: data });
  } catch (err) {
    //
  }
};

export const categoryActions = {
  getCategories,
};

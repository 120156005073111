import { GlobalServiceAdapter } from "../helpers/services";
import * as Types from "./types";

const initialState = {
  errors: null,
  user: null,
  // loading:false,
  updateUserImage: null,
  notifications: null,
  seeNotification: null,
  deleteNotification: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    // case Types.LOADING : {
    //   return {...state, loading: true}
    // }

    case Types.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        user: payload,
        errors: null,
        // loading: false
      };
    }
    case Types.GET_USER_INFO_FAILED: {
      return {
        ...state,
        errors: payload,
        // loading: false
      };
    }
    case Types.USER_UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        updateUserImage: payload,
        errors: null,
        // loading: false
      };
    }
    case Types.USER_UPDATE_PROFILE_FAILED: {
      return {
        ...state,
        errors: payload,
        updateUserImage: null,
        // loading: false
      };
    }
    case Types.USER_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        notifications: payload,
        errors: null,
        // loading: false
      };
    }
    case Types.USER_NOTIFICATION_FAILED: {
      return {
        ...state,
        errors: payload,
        notifications: null,
        // loading: false
      };
    }
    case Types.SEE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        seeNotification: payload,
        errors: null,
        // loading: false
      };
    }
    case Types.SEE_NOTIFICATION_FAILED: {
      return {
        ...state,
        errors: payload,
        // loading: false
      };
    }

    case Types.DELETE_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        deleteNotification: payload,
        errors: null,
        // loading: false
      };
    }
    case Types.DELETE_NOTIFICATION_FAILED: {
      return {
        ...state,
        errors: payload,
        // loading: false
      };
    }

    case Types.CLEAN_USER_ERRORS: {
      return {
        ...state,
        errors: null,
        updateUserImage: null,
        // loading: false
      };
    }
    case Types.CLEAN_USER_INFO: {
      return {
        ...state,
        user: null,
        // loading: false
      };
    }
    default: {
      return state;
    }
  }
};

export const getNotifications = () => async (dispatch) => {
  try {
    // ...
    const data = await GlobalServiceAdapter.fetchData("users/notifications");

    dispatch({
      type: Types.USER_NOTIFICATION_SUCCESS,
      payload: data.notifications,
    });
  } catch (err) {
    // ...
    dispatch({
      type: Types.USER_NOTIFICATION_FAILED,
      payload: err.response?.data,
    });
  }
};

const deleteNotification = (id) => async (dispatch) => {
  try {
    const response = await GlobalServiceAdapter.deleteData(
      `users/notifications/${id}`
    );
    dispatch({
      type: Types.DELETE_NOTIFICATION_SUCCESS,
      payload: response?.data,
    });

    dispatch({ type: Types.USER_NOTIFICATION_SUCCESS });
  } catch (err) {
    dispatch({
      type: Types.DELETE_NOTIFICATION_FAILED,
      payload: err.response?.data,
    });
  }
};

export const userActions = {
  deleteNotification,
  getNotifications,
};

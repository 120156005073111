import Loader from "../../assets/logos/min-logo.png";

export const Loading = () => {
  return (
    <div className="container d-flex justify-content-center">
      {/* <div className="sweet-loading d-flex flex-row justify-content-center align-items-center">
        <div className="css-igmv6f"></div>
      </div> */}
      <img className="loader-logo" src={Loader} alt="" />
    </div>
  );
};

import React from "react";
import { Redirect } from "react-router-dom";
import { systemStatus } from "../helpers/auth.status";
import { PrivateRoute } from "./private-route";

export const StudentAndInstructor = (props) => {
  if (systemStatus.getUserInfo().roles?.find((role) => role.level === 1)) {
    return <PrivateRoute exact path={props.path} component={props.component} />;
  } else if (
    systemStatus.getUserInfo().roles?.find((role) => role.level === 2)
  ) {
    return <PrivateRoute exact path={props.path} component={props.component} />;
  } else {
    return <Redirect to={`/`} />;
  }
};

// login action's types
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const LOADING = "LOADING";

// signup action's types
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILED = "SIGNUP_FAILED";
// age groups action's types
export const GET_AGE_GROUPS = "GET_AGE_GROUPS";

// languages
export const LANG = "LANG";

// currencies action's types
export const GET_CURRENCY = "GET_CURRENCY";

// logout action type
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILED = "LOGOUT_FAILED";

// remember me
export const REMEMBER_ME_SUCCESS = "REMEMBER_ME_SUCCESS";
export const REMEMBER_ME_FAILED = "REMEMBER_ME_FAILED";

// get User info types
export const GET_USER_INFO_SUCCESS = "GET_USER_INFO_SUCCESS";
export const GET_USER_INFO_FAILED = "GET_USER_INFO_FAILED";

// INSTRUCTOR course upload types
export const INS_COURSE_UPLOAD_SUCCESS = "COURSE_UPLOAD_SUCCESS";
export const INS_COURSE_UPLOAD_FAILED = "COURSE_UPLOAD_FAILED";

// COURSE rate types
export const COURSE_RATE_SUCCESS = "COURSE_RATE_SUCCESS";
export const COURSE_RATE_FAILED = "COURSE_RATE_FAILED";

// BEST courses types
export const BEST_COURSES_SUCCESS = "BEST_COURSES_SUCCESS";
export const BEST_COURSES_FAILED = "BEST_COURSES_FAILED";

// INSTRUCTOR lecture upload types
export const INS_LECTURE_UPLOAD_SUCCESS = "LECTURE_UPLOAD_SUCCESS";
export const INS_LECTURE_UPLOAD_FAILED = "LECTURE_UPLOAD_FAILED";

// INSTRUCTOR update course
export const INS_COURSE_UPDATE_SUCCESS = "INS_COURSE_UPDATE_SUCCESS";
export const INS_COURSE_UPDATE_FAILED = "INS_COURSE_UPDATE_FAILED";

// INSTRUCTOR capsula upload types
export const INS_CAPSULA_UPLOAD_SUCCESS = "CAPSULA_UPLOAD_SUCCESS";
export const INS_CAPSULA_UPLOAD_FAILED = "CAPSULA_UPLOAD_FAILED";

// INSTRUCTOR course get types
export const INS_COURSE_GET_SUCCESS = "COURSE_GET_SUCCESS";
export const INS_COURSE_GET_FAILED = "COURSE_GET_FAILED";

// ALL COURSES
export const ALL_COURSES_SUCCESS = "ALL_COURSES_SUCCESS";
export const ALL_COURSES_FAILED = "ALL_COURSES_FAILED";
export const CLEAR_ONE_COURSE = "CLEAR_ONE_COURSE";

// COURSE search
export const SEARCH_COURSE = "SEARCH_COURSE";
export const SEARCH_COURSE_FAILED = "SEARCH_COURSE_FAILED";

// INSTRUCTOR course get types
export const INS_COURSE_SUCCESS = "INS_COURSE_SUCCESS";
export const INS_COURSE_FAILED = "INS_COURSE_FAILED";

// COURSES get one course

export const GET_ONE_COURSE_SUCCESS = "GET_ONE_COURSE_SUCCESS";
export const GET_ONE_COURSE_FAILED = "GET_ONE_COURSE_FAILED";

// PAYMENT create paying account
export const CREATE_PAYMENT_SUCCESS = "CREATE_PAYMENT_SUCCESS";
export const CREATE_PAYMENT_FAILED = "CREATE_PAYMENT_FAILED";

// PAYMENT update paying account
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";
export const UPDATE_PAYMENT_FAILED = "UPDATE_PAYMENT_FAILED";

// ENROLLMENT a course
export const ENROLLMENT_SUCCESS = "ENROLLMENT_SUCCESS";
export const ENROLLMENT_FAILED = "ENROLLMENT_FAILED";

// BILLING infomations
export const BILLING_ACCOUNT_INFO_SUCCESS = "BILLING_ACCOUNT_SUCCESS";
export const BILLING_ACCOUNT_INFO_FAILED = "BILLING_ACCOUNT_FAILED";

// BILLING infomations
export const AVAILABLE_BILLING_ACCOUNT_INFO_SUCCESS =
  "AVAILABLE_BILLING_ACCOUNT_INFO_SUCCESS";
export const AVAILABLE_BILLING_ACCOUNT_INFO_FAILED =
  "AVAILABLE_BILLING_ACCOUNT_INFO_FAILED";

// GET COURSE COMMENTS
export const GET_COURSE_COMMENTS_SUCCESS = "GET_COURSE_COMMENTS_SUCCESS";
export const GET_COURSE_COMMENTS_FAILED = "GET_COURSE_COMMENTS_FAILED";

// Get student courses
export const GET_ENROLLED_COURSES_SUCCESS = "GET_ENROLLED_COURSES_SUCCESS";
export const GET_ENROLLED_COURSES_FAILED = "GET_ENROLLED_COURSES_FAILED";

// update user image
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAILED = "USER_UPDATE_PROFILE_FAILED";

// user notifications
export const USER_NOTIFICATION_SUCCESS = "USER_NOTIFICATION_SUCCESS";
export const USER_NOTIFICATION_FAILED = "USER_NOTIFICATION_FAILED";

// user delete notification
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILED = "DELETE_NOTIFICATION_FAILED";

// update user information
export const UPDATE_USER_INFO_SUCCESS = "UPDATE_USER_INFO_SUCCESS";
export const UPDATE_USER_INFO_FAILED = "UPDATE_USER_INFO_FAILED";

// update user password
export const UPDATE_USER_PASSWORD_SUCCESS = "UPDATE_USER_PASSWORD_SUCCESS";
export const UPDATE_USER_PASSWORD_FAILED = "UPDATE_USER_PASSWORD_FAILED";

export const UPDATE_COURSE_IMAGE_SUCCESS = "UPDATE_COURSE_IMAGE_SUCCESS";
export const UPDATE_COURSE_IMAGE_FAILED = "UPDATE_COURSE_IMAGE_FAILED";

// set roles
export const SET_USER_ROLE_SUCCESS = "SET_USER_ROLE_SUCCESS";
export const SET_USER_ROLE_FAILED = "SET_USER_ROLE_FAILED";

// get course lectures
export const GET_COURSE_LECTURES_SUCCESS = "GET_COURSE_LECTURES_SUCCESS";
export const GET_COURSE_LECTURES_FAILED = "GET_COURSE_LECTURES_FAILED";

// GET COURSE LECTURE PLAYLIST
export const GET_COURSE_PLAYLIST_SUCCESS = "GET_COURSE_PLAYLIST_SUCCESS";
export const GET_COURSE_PLAYLIST_FAILED = "GET_COURSE_PLAYLIST_FAILED";

// GET COURSE LECTURE PLAYLIST
export const ANSWER_QUESTION_SUCCESS = "ANSWER_QUESTION_SUCCESS";
export const ANSWER_QUESTION_FAILED = "ANSWER_QUESTION_FAILED";

// cleaners all bellow states to insure consistency
export const CLEAN_SESSION_ERRORS = "CLEAN_SESSION_ERRORS";
export const CLEAN_PAYMENT_ERRORS = "CLEAN_PAYMENT_ERRORS";
export const CLEAN_USER_ERRORS = "CLEAN_USER_ERRORS";
export const CLEAR_UPDATE_COVER = "CLEAR_UPDATE_COVER";
export const CLEAN_MY_COURSES = "CLEAN_MY_COURSES";
export const CLEAN_MY_BILLING_ACCOUNTS = "CLEAN_MY_BILLING_ACCOUNTS";
export const CLEAN_COURSE_LECTURES = "CLEAN_COURSE_LECTURES";
export const CLEAN_CLASSROOM = "CLEAN_CLASSROOM";
export const CLEAN_INSTRUCTOR_EARNS = "CLEAN_INSTRUCTOR_EARNS";
export const CLEAN_STUDENT_COURSES = "CLEAN_STUDENT_COURSES";
export const CLEAN_USER_INFO = "CLEAN_USER_INFO";
export const CLEAN_USER_SESSION_INFO = "CLEAN_USER_SESSION_INFO";
export const CLEAN_UPLOADED_COURSE = "CLEAN_UPLOADED_COURSE";

// instructor earns
export const GET_INSTRUCTOR_EARNS_SUCCESS = "GET_INSTRUCTOR_EARNS_SUCCESS";
export const GET_INSTRUCTOR_EARNS_ERROR = "GET_INSTRUCTOR_EARNS_ERROR";

// instructor earns
export const GET_WITHDRAWAL_EARNS_SUCCESS = "GET_WITHDRAWAL_EARNS_SUCCESS";
export const GET_WITHDRAWAL_EARNS_ERROR = "GET_WITHDRAWAL_EARNS_ERROR";

// get visited course
export const VISIT_COURSE = "VISIT_COURSE";
export const VISIT_COURSE_FAILED = "VISIT_COURSE_FAILED";

export const SEE_NOTIFICATION_SUCCESS = "SEE_NOTIFICATION_SUCCESS";
export const SEE_NOTIFICATION_FAILED = "SEE_NOTIFICATION_FAILED";

//************* */
export const ADD_COURSE_COMMENTS_SUCCESS = "ADD_COURSE_COMMENTS_SUCCESS";

export const GET_COURSE_ENROLLERS = "GET_COURSE_ENROLLERS";
export const CLEAR_ANSWER = "CLEAR_ANSWER";

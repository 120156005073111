import { env } from "../env";
import devJsonKeys from "./dev.json";
import prodJsonKeys from "./prod.json";
interface IConfigs {
  api: string;
  storage_api: string;
  web_url: string;
}

let configs: IConfigs;

if (env === "dev") {
  configs = devJsonKeys;
} else if (env === "prod") {
  configs = prodJsonKeys;
}

export { configs };

import Logo from "../navbar/logo";
import { systemStatus } from "../../../helpers/auth.status";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";

const MainFooter = () => {
  // const changeLang = (lang) => {
  //   systemStatus.changeSystemLang(lang);
  // };

  return (
    <div className="">
      <footer>
        <div className="container">
          <div className="row m-0">
            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 has-margin-top text-righted">
              <h5>
                <Logo width="90px" />
              </h5>
              <p>
                <Trans>about-footer</Trans>
              </p>
            </div>

            <div className="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
              <ul>
                <li>
                  <Link to="/about-us">
                    <h5>
                      <Trans>about-us</Trans>
                    </h5>
                  </Link>
                </li>
                {/* <li>
                  <a href="/">
                    <Trans>FAQ</Trans>
                  </a>
                </li> */}
                <li>
                  <a href="/term-of-use">
                    <Trans>policies</Trans>
                  </a>
                </li>
                <li>
                  <a href="/register">
                    <Trans>signup-instructor</Trans>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <Trans>signup-institute</Trans>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-12  col-sm-6 col-md-6 col-lg-4 col-xl-4">
              <ul>
                <li>
                  <h5>
                    <Trans>msg-us</Trans>
                  </h5>
                </li>
                <li>
                  <p>
                    <Trans>msg-us-text</Trans>
                  </p>
                </li>
              </ul>

              <div className="d-flex p-0 col-12 col-sm-6 col-md-12 col-lg-12">
                <span>
                  <a href="https://fb.watch/9KHvzhLay0/" target="_blank">
                    <i className="fab fa-facebook "></i>
                  </a>
                </span>

                <a
                  href="https://www.instagram.com/kpsolla_sudan"
                  target="_blank"
                >
                  <i className="fab fa-instagram "></i>
                </a>

                <a href="#">
                  <i className="fab fa-twitter "></i>
                </a>

                <a href="https://www.linkedin.com/in/capsula-%D9%83%D8%A8%D8%B3%D9%88%D9%84%D8%A9-85aa6b202/">
                  <i className="fab fa-linkedin "></i>
                </a>
              </div>

              <div className="  p-0   my-4 col-12 col-sm-6 col-md-12 col-lg-12">
                <div className="d-flex ">
                  <i className="far fa-map-marker"></i>{" "}
                  <span> الخرطوم , الرياض , شارع 112 </span>
                </div>
                <div className="d-flex  ">
                  <i className="far fa-phone"></i>{" "}
                  <span>
                    <a dir="ltr" href="tel://3490">
                      3490
                    </a>{" "}
                    -{" "}
                    <a dir="ltr" href="tel://+249183529559">
                      +249183529559
                    </a>
                  </span>
                </div>
                <div className="d-flex ">
                  <i className="far fa-envelope"></i>{" "}
                  <span>
                    <a href="mailto:info.kpsolla@oxygenict.com">
                      info.kpsolla@oxygenict.com
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* 
          <div className="copyRights container d-flex text-center ">
            <p>
              <Trans>rights</Trans> &#169; {new Date().getFullYear() + " "}
            </p>
            <div className="">
              <select
                className="langs form-control form-control-sm"
                style={{
                  width: "80px",
                  backgroundColor: "transparent",
                  border: "none",
                  color: "white",
                }}
                defaultValue={localStorage.lang}
                onChange={(event) => changeLang(event.target.value)}
              >
                <option className="text-dark" value={"ar"}>
                  العربية
                </option>
                <option className="text-dark" value={"en"}>
                  English
                </option>
              </select>
            </div>
          </div> */}
        </div>
        <div className="copy-rights p-3 text-center">
          <span>
            <Trans>rights</Trans> &#169; {new Date().getFullYear() + " "}
          </span>
        </div>
      </footer>
    </div>
  );
};

// export default MainFooter;
export default MainFooter;

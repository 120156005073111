import notify from "../components/common/notification-system/notification";
import i18n from "../configs/i18n";
import {
  GlobalServiceAdapter,
  ServiseAdapterCustomVersion,
} from "../helpers/services";
import { GET_USER_INFO_SUCCESS } from "./types";
import FileDownload from "js-file-download";

const GET_COURSES = "COURSES/GET_COURSES";
const GET_ONE_COURSE = "COURSES/GET_ONE_COURSE";
const LOADING = "COURSES/LOADING";
const ERRORS = "COURSES/ERRORS";
const GET_COURSE_COMMENTS = "COURSES/GET_COURSE_COMMENTS";
const GET_COURSE_PLAYLIST = "COURSES/GET_COURSE_PLAYLIST";
const ANSWER_QUESTION = "COURSES/ANSWER_QUESTION";
const GET_COURSE_LECTURES = "COURSES/GET_COURSE_LECTURES";
const GET_COURSE_SUBSCRIBERS = "COURSES/GET_COURSE_SUBSCRIBERS";
const GET_BEST_COURSESES = "COURSES/GET_BEST_COURSESES";
const GET_HOME_PAGE_INFO = "COURSES/GET_HOME_PAGE_INFO";
const GET_UNCOMPLETED_COURSE = "COURSES/GET_UNCOMPLETED_COURSE";
const GET_TEACHER_INFO = "COURSES/GET_TEACHER_INFO";
const GET_PROMOTIONS_COURSES = "COURSES/GET_PROMOTIONS_COURSES";
const SET_UPLOAD_PERCENTAGE = "COURSES/SET_UPLOAD_PERCENTAGE";
const DEFAULT_STATE = "COURSES/DEFAULT_STATE";
const CLEAR_PLAYLIST = "COURSES/CLEAR_PLAYLIST";

const initialValues = {
  loading: false,
  courses: [],
  errors: null,
  course: null,
  comments: [],
  lectureAdded: false,
  correctAnswer: "no-answer",
  progressPercentage: 0,
};
export default function (state = initialValues, { type, payload }) {
  switch (type) {
    case GET_COURSES: {
      return {
        ...state,
        loading: false,
        courses: payload,
        errors: null,
      };
    }

    case DEFAULT_STATE: {
      return {
        ...state,
        loading: false,
        lectureAdded: false,
        progressPercentage: 0,
      };
    }
    case GET_ONE_COURSE: {
      return {
        ...state,
        loading: false,
        errors: null,
        course: payload,
      };
    }
    case GET_PROMOTIONS_COURSES: {
      return {
        ...state,
        loading: false,
        errors: null,
        promotions: payload,
      };
    }
    case GET_COURSE_SUBSCRIBERS: {
      return {
        ...state,
        loading: false,
        errors: null,
        subscribers: payload,
      };
    }
    case GET_UNCOMPLETED_COURSE: {
      return {
        ...state,
        loading: false,
        errors: null,
        uncompleted: payload,
      };
    }
    case GET_BEST_COURSESES: {
      return {
        ...state,
        loading: false,
        errors: null,
        courses: payload,
      };
    }
    case ANSWER_QUESTION: {
      return {
        ...state,
        loading: false,
        errors: null,
        correctAnswer: payload,
      };
    }
    case GET_COURSE_PLAYLIST: {
      return {
        ...state,
        loading: false,
        errors: null,
        playlist: payload,
      };
    }
    case GET_COURSE_COMMENTS: {
      return {
        ...state,
        loading: false,
        errors: null,
        comments: payload,
      };
    }
    case GET_COURSE_LECTURES: {
      return {
        ...state,
        loading: false,
        errors: null,
        lectures: payload,
      };
    }

    case GET_HOME_PAGE_INFO: {
      return {
        ...state,
        ...payload,
        errors: null,
        loading: false,
      };
    }
    case GET_TEACHER_INFO: {
      return {
        ...state,
        errors: null,
        teacher: payload,
        loading: false,
      };
    }

    case "LECTURE_CREATED_SUCCESSFULLY": {
      return {
        ...state,
        loading: false,
        lectureAdded: true,
      };
    }
    case LOADING: {
      return {
        ...state,
        loading: true,
        errors: null,
        // lectureAdded: false,
      };
    }
    case ERRORS: {
      return {
        ...state,
        loading: false,
        errors: payload,
      };
    }

    case SET_UPLOAD_PERCENTAGE: {
      return {
        ...state,
        progressPercentage: payload,
      };
    }

    case CLEAR_PLAYLIST: {
      return {
        ...state,
        errors: null,
        correctAnswer: "no-answer",
      };
    }
    default: {
      return state;
    }
  }
}

const getCourses = (query) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      "v2/courses/get" + query
    );
    dispatch({ type: GET_COURSES, payload: data?.courses });
  } catch (err) {
    console.log(err);
  }
};

const getPartnerCourses = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapter.fetchData(
      "institutes/getInstituteCourses/" + id
    );

    console.log(data);

    dispatch({ type: GET_COURSES, payload: data?.courses });
  } catch (err) {
    console.log(err);
  }
};

const getLandingPageCourses = () => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapter.fetchData("");

    console.log(data);
  } catch (err) {
    console.log(err);
  }
};
const getOneCourse = (id, loading?: boolean) => async (dispatch) => {
  if (loading) {
    dispatch({ type: LOADING });
  }
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      "v2/courses/get/" + id
    );
    // console.log(data);

    dispatch({ type: GET_ONE_COURSE, payload: data });
  } catch (err) {
    console.log(err);
  }
};
const getCourseById = (id: string) => async (dispatch) => {
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      "v2/courses/" + id
    );

    dispatch({ type: GET_ONE_COURSE, payload: data });
  } catch (err) {
    console.log(err);
  }
};
const updateCourse = (id: string, info: any) => async (dispatch) => {
  try {
    const data = await ServiseAdapterCustomVersion.updateData(
      "v2/courses/" + id,
      info
    );
    // console.log(data);
    notify(i18n.t("success"), i18n.t("course-updated-successfully"), "success");

    // dispatch({ type: GET_ONE_COURSE, payload: data });
  } catch (err) {
    console.log(err);
  }
};
const getCourseComments = (id) => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapter.fetchData(`courses/${id}/comments`);
    dispatch({ type: GET_COURSE_COMMENTS, payload: data });
  } catch (err) {
    console.log(err);
  }
};
const addCourseComment = (id, comment) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.sendData(`courses/${id}/addComment`, comment);
    getCourseComments(id)(dispatch);
  } catch (err) {
    console.log(err);
  }
};
const rateCourse = (id, rate) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.sendData(`courses/${id}/rate`, rate);

    // getOneCourse(`id=${id}`)(dispatch);
  } catch (err) {
    console.log(err);
  }
};
const getStudentCourses = (query) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      `v2/courses/recent-courses?filter=${query}`
    );
    dispatch({ type: GET_COURSES, payload: data });
  } catch (err) {
    console.log("=====", err);
  }
};
const getInstructorCourses = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      `v2/courses/instructorCourses`
    );
    // console.log(data);
    dispatch({ type: GET_COURSES, payload: data });
  } catch (err) {
    console.log(err);
  }
};
const getCoursePlayList = (courseId) => async (dispatch) => {
  try {
    const { lectures } = await GlobalServiceAdapter.fetchData(
      `courses/${courseId}/playlist`
    );
    dispatch({ type: GET_COURSE_PLAYLIST, payload: lectures });
  } catch (err) {}
};
const lectureQuestion = (id, data) => async (dispatch) => {
  try {
    const response = await GlobalServiceAdapter.sendData(
      `user-tracking/record-progress`,
      data
    );

    if (response?.correctAnswer === false) {
      dispatch({
        type: ANSWER_QUESTION,
        payload: "incorrect",
      });
    } else {
      dispatch({
        type: ANSWER_QUESTION,
        payload: "correct",
      });
    }

    // getCoursePlayList(id)(dispatch);
    // dispatch({ type: GET_COURSE_PLAYLIST, payload: lectures });
  } catch (err) {}
};

const getCourseLectures = (id, noLoading?: boolean) => async (dispatch) => {
  if (!noLoading) {
    dispatch({ type: LOADING });
  }
  try {
    // const data = await ServiseAdapterCustomVersion.fetchData(
    //   `v2/courses/getUsersProgressReport/${id}`
    // );

    // console.log(data);

    const { lectures } = await GlobalServiceAdapter.fetchData(
      `courses/${id}/lectures`
    );
    dispatch({ type: GET_COURSE_LECTURES, payload: lectures });
  } catch (err) {}
};

const getCourseReports = (id, noLoading?: boolean) => async (dispatch) => {
  if (!noLoading) {
    dispatch({ type: LOADING });
  }
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      `v2/courses/getUsersProgressReport/${id}`
    );

    // console.log(data);

    // const { lectures } = await GlobalServiceAdapter.fetchData(
    //   `courses/${id}/lectures`
    // );
    dispatch({ type: GET_COURSE_LECTURES, payload: data });
  } catch (err) {}
};

const getCourseSubscribers = (id) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapter.fetchData(`users/${id}/enrollers`);
    dispatch({ type: GET_COURSE_SUBSCRIBERS, payload: data });
  } catch (err) {}
};
const createCourse = (info) => async (dispatch) => {
  try {
    const data = await ServiseAdapterCustomVersion.sendData(
      `v2/courses/`,
      info
    );
    // console.log(data);
    // window.location.replace("/dashboard/my-courses");
    // dispatch({ type: GET_COURSE_SUBSCRIBERS, payload: data });
  } catch (err) {}
};
const bestCourses = () => async (dispatch) => {
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      `v2/courses/bestCourses`
    );

    // console.log({ data });

    dispatch({ type: GET_BEST_COURSESES, payload: data });
  } catch (err) {}
};
const likeCourse = (courseId) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.sendData(`courses/${courseId}/liked`, {});
    // const data = await GlobalServiceAdapter.fetchData(`courses/${courseId}/liked`, {});

    // console.log("=========", data);
    getCourseById(courseId)(dispatch);
    // dispatch({type: GET_ONE_COURSEM,})
  } catch (err) {}
};
const dislikeCourse = (courseId) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.sendData(`courses/${courseId}/disliked`, {});
    // getOneCourse(`id=${courseId}`)(dispatch);
    getCourseById(courseId)(dispatch);
  } catch (err) {}
};
const shareCourse = (courseId) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.sendData(`courses/${courseId}/shared`, {});
    // getOneCourse(`id=${courseId}`)(dispatch);
    getCourseById(courseId)(dispatch);
  } catch (err) {}
};
const viewcourse = (courseId) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.sendData(`courses/${courseId}/viewed`, {});
    // getOneCourse(`id=${courseId}`)(dispatch);
    getCourseById(courseId)(dispatch);
  } catch (err) {}
};

const createLecture = (id, data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await GlobalServiceAdapter.sendFiles(`courses/${id}/lectures/add`, data);
    dispatch({ type: "LECTURE_CREATED_SUCCESSFULLY" });
    getCourseLectures(id)(dispatch);
  } catch (err) {}
};

const getHomePageInfo = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      `v2/courses/home/info`
    );

    dispatch({ type: GET_HOME_PAGE_INFO, payload: data });
  } catch (err) {}
};

const uploadCapsula = (id, info) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.sendFiles(`courses/${id}/capsula/add`, info);

    getInstructorCourses()(dispatch);

    // dispatch({ type: GET_HOME_PAGE_INFO, payload: data });
  } catch (err) {}
};

const updateCapsula = (id, info) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.updateFiles(
      `courses/${id}/capsula/update`,
      info
    );
    notify(i18n.t("success"), i18n.t("course-updated-successfully"), "success");

    // dispatch({ type: GET_HOME_PAGE_INFO, payload: data });
  } catch (err) {}
};

const updateCourseImage = (id, info) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.updateFiles(`courses/${id}/updateImage`, info);

    notify(i18n.t("success"), i18n.t("course-updated-successfully"), "success");

    // dispatch({ type: GET_HOME_PAGE_INFO, payload: data });
  } catch (err) {}
};

const getUncompletedCourse = () => async (dispatch) => {
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      `v2/courses/uncompleted`
    );
    dispatch({ type: GET_UNCOMPLETED_COURSE, payload: data });
  } catch (err) {}
};

const uploadCourseImage = (info) => async (dispatch) => {
  try {
    const data = await ServiseAdapterCustomVersion.updateFiles(
      `v2/courses/course-image`,
      info
    );

    dispatch({ type: GET_UNCOMPLETED_COURSE, payload: data });
  } catch (err) {}
};

const publishCoures = () => async (dispatch) => {
  try {
    await ServiseAdapterCustomVersion.updateData(
      `v2/courses/publish-course`,
      {}
    );

    // console.log(data);
    // window.location.replace(`/courses/${data?.id}`);
    window.location.replace("/dashboard/my-courses");

    // dispatch({ type: GET_UNCOMPLETED_COURSE, payload: data });
  } catch (err) {
    notify("error", err?.message, "danger");
    // notify(err.response?.message)
  }
};

const getInstructorInfo = (query) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      `v1/users/teacher-info?username=${query.teacher}`
    );
    // console.log(data);
    dispatch({ type: GET_TEACHER_INFO, payload: data });
  } catch (err) {
    // notify("error", err?.message, "danger");
    // notify(err.response?.message)
  }
};
const getCoursesPromotions = () => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      `v2/courses/promotions/new`
    );

    dispatch({ type: GET_PROMOTIONS_COURSES, payload: data });
  } catch (err) {}
};

const getVisitedCourse = (title) => async (dispatch) => {
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      `v2/courses/get-course-by-title/${title}`
    );

    dispatch({ type: GET_ONE_COURSE, payload: data });
  } catch (err) {}
};
const getCoursesEnrollments = (title) => async (dispatch) => {
  try {
    const data = await ServiseAdapterCustomVersion.fetchData(
      `v2/courses/enrollment-courses`
    );

    // console.log(data)
    dispatch({ type: GET_COURSES, payload: data });
  } catch (err) {}
};
const opserveUploadProgress = (percentage: number) => async (dispatch) => {
  dispatch({ type: SET_UPLOAD_PERCENTAGE, payload: percentage });
};

const clearnLectureAddition = () => (dispatch) => {
  dispatch({ type: DEFAULT_STATE });
};

const issueCertificate = (id, info) => async (dispatch) => {
  // dispatch({ type: DEFAULT_STATE });
  try {
    const data = await ServiseAdapterCustomVersion.download(
      "v2/courses/issue-certificate/" + id + "?studentName=" + info.studentName
    );

    FileDownload(data, `${new Date().getTime()}.pdf`);
  } catch (err) {
    notify(i18n.t("error"), err?.message, "danger");
    console.log(err);
  }
};

const requestInstituteCertificate = (id, info) => async (dispatch) => {
  // dispatch({ type: DEFAULT_STATE });
  try {
    await ServiseAdapterCustomVersion.sendData(
      "v2/courses/request-completion-certificate/" + id,
      info
    );
    notify(i18n.t("success"), i18n.t("request-sent-successfully"), "success");

    // FileDownload(data, `${new Date().getTime()}.pdf`);
  } catch (err) {
    notify(i18n.t("error"), err?.message, "danger");
    console.log(err);
  }
};

const setCourseAsFree = (id) => async (dispatch) => {
  // dispatch({ type: DEFAULT_STATE });
  try {
    const data = await ServiseAdapterCustomVersion.updateData(
      "v2/courses/lecture-type/set-free/" + id,
      {}
    );

    getCourseLectures(data.course.id, true)(dispatch);
    // console.log(data);
  } catch (err) {
    console.log(err);
  }
};

const clearPlaylist = () => async (dispatch) => {
  dispatch({ type: CLEAR_PLAYLIST });
};

export const courseActions = {
  getCourses,
  clearnLectureAddition,
  getLandingPageCourses,
  getOneCourse,
  getCourseComments,
  addCourseComment,
  rateCourse,
  getStudentCourses,
  getCoursePlayList,
  lectureQuestion,
  getInstructorCourses,
  getCourseLectures,
  getCourseSubscribers,
  createCourse,
  bestCourses,
  likeCourse,
  dislikeCourse,
  shareCourse,
  viewcourse,
  createLecture,
  uploadCapsula,
  getHomePageInfo,
  getUncompletedCourse,
  uploadCourseImage,
  publishCoures,
  getInstructorInfo,
  getCoursesPromotions,
  getVisitedCourse,
  getCoursesEnrollments,
  opserveUploadProgress,
  getCourseById,
  updateCourse,
  issueCertificate,
  setCourseAsFree,
  updateCapsula,
  updateCourseImage,
  requestInstituteCertificate,
  clearPlaylist,
  getPartnerCourses,
  getCourseReports,
};

import { lazy } from "react";

export const Playlist = lazy(() => import("./playlist/Playlist"));
export const Filter = lazy(() => import("./filter/Filter"));
export const Login = lazy(() => import("./auth/Login"));
export const RestorePassword = lazy(() => import("./auth/RestorePassword"));
export const VerifyAccount = lazy(() => import("./auth/VerifyAccount"));
export const SignAs = lazy(() => import("./auth/Signas"));
export const Categories = lazy(() => import("./categories/Categories"));

export const SignStudent = lazy(() => import("./auth/Signup"));
export const SignInstructor = lazy(() => import("./auth/SignIns"));
export const Landing = lazy(() => import("./landing/Landing"));
export const AboutUs = lazy(() => import("./landing/AboutUs"));
export const Terms = lazy(() => import("./terms/Terms"));
export const Course = lazy(() => import("./course/Course"));
export const Billing = lazy(() => import("./billing/Billing"));
export const Profile = lazy(() => import("./profile/Profile"));
export const Dashboard = lazy(() => import("./dashboard/Home"));
export const DashboardCourses = lazy(() => import("./dashboard/Courses"));
export const Earns = lazy(() => import("./dashboard/Earns"));
export const IncomeDetails = lazy(() => import("./dashboard/IncomeDetails"));
export const Lectures = lazy(() => import("./dashboard/Lectures"));
export const Subscribers = lazy(() => import("./dashboard/Subscribers"));
export const CreateCourse = lazy(() => import("./dashboard/CreateCourse"));
export const UpdateCourse = lazy(() => import("./dashboard/UpdateCourse"));
export const Settings = lazy(() => import("./settings/Settings"));
export const Forbidden = lazy(() => import("./forbidden/Forbidden"));
export const Enrollments = lazy(() => import("./enrollment/Enrollments"));
export const NotFound = lazy(() => import("./forbidden/NotFound"));
export const ReviewCourse = lazy(() => import("./course/ReviewCourse"));
export const TeacherProfile = lazy(() => import("./profile/TeacherProfile"));
export const CreateLecture = lazy(() => import("./dashboard/CreateLecture"));
export const VerifyEmail = lazy(() => import("./verify-email/VerifyEmail"));
export const ResetPassword = lazy(() => import("./auth/ResetPassword"));
export const Classroom = lazy(() => import("./classroom"));
export const PartnerProfile = lazy(() => import("./profile/PartnerProfile"));

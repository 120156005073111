import * as Types from '../types';

const initialState = {
  course: null,
  errors: null,
};

export const InstructorGetCourse = (
  state = initialState,
  { type, payload },
) => {
  switch (type) {
    case Types.INS_COURSE_GET_SUCCESS: {
      return {
        ...state,
        course: payload,
        errors: null,
      };
    }
    case Types.INS_COURSE_GET_FAILED: {
      return {
        ...state,
        course: null,
        errors: payload.errors,
      };
    }
    default: {
      return state;
    }
  }
};

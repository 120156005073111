import { Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import * as pages from "../pages";
import { PrivateRoute } from "../router/private-route";

import { Guest, NonAuthenticatedRoute } from "../router/guest";
import { StudentAndInstructor } from "../router/student&instructor-route";
import { InstructorRoute } from "../router/instructor-route";
import Layout from "../components/layout/DashboardLayout";

const AppRouter = (props) => {
  return (
    <Switch>
      <Guest {...props} exact path="/" component={pages.Landing} />
      <NonAuthenticatedRoute
        {...props}
        exact
        path="/login"
        component={pages.Login}
      />
      <NonAuthenticatedRoute
        {...props}
        exact
        path="/register"
        component={pages.SignStudent}
      />{" "}
      <Guest {...props} exact path="/term-of-use" component={pages.Terms} />
      <Route {...props} exact path="/home" component={pages.Landing} />
      <Route {...props} exact path="/courses/visit" component={pages.Course} />
      <Route
        {...props}
        exact
        path="/partners/:id"
        component={pages.PartnerProfile}
      />
      <PrivateRoute
        {...props}
        exact
        path="/users/visit"
        component={pages.TeacherProfile}
      />
      <Route {...props} exact path="/search" component={pages.Filter} />
      <PrivateRoute
        {...props}
        exact
        path="/settings"
        component={pages.Settings}
      />
      <PrivateRoute
        {...props}
        exact
        path="/payments/courses"
        component={pages.Enrollments}
      />
      <PrivateRoute
        {...props}
        exact
        path="/incompleted-course/review/"
        component={pages.ReviewCourse}
      />{" "}
      <Route
        {...props}
        exact
        path="/account/verify_email"
        component={pages.VerifyEmail}
      />
      <Route
        {...props}
        exact
        path="/account/verify-account"
        component={pages.VerifyAccount}
      />
      <Route
        {...props}
        exact
        path="/account/restore-password"
        component={pages.RestorePassword}
      />
      <Route
        {...props}
        exact
        path="/account/reset-password"
        component={pages.ResetPassword}
      />
      <Route {...props} exact path="/categories" component={pages.Categories} />
      <StudentAndInstructor
        {...props}
        exact
        path="/classroom/:id"
        component={pages.Classroom}
      />
      <PrivateRoute
        {...props}
        exact
        path="/payments/billing"
        component={pages.Billing}
      />
      <PrivateRoute
        {...props}
        exact
        path="/profile"
        component={pages.Profile}
      />
      <PrivateRoute
        {...props}
        exact
        path="/about-us"
        component={pages.AboutUs}
      />
      <Layout>
        <InstructorRoute
          {...props}
          exact
          path="/dashboard/my-courses"
          component={pages.DashboardCourses}
        />
        <InstructorRoute
          {...props}
          exact
          path="/dashboard/my-courses/update-course"
          component={pages.UpdateCourse}
        />

        {/* <InstructorRoute
          {...props}
          exact
          path="/dashboard/courses"
          component={pages.DashboardCourses}
        /> */}
        <InstructorRoute
          {...props}
          exact
          path="/dashboard/course-earns"
          component={pages.Earns}
        />
        <InstructorRoute
          {...props}
          exact
          path="/dashboard/my-courses/create-lecture"
          component={pages.CreateLecture}
        />

        {/* <InstructorRoute
          {...props}
          exact
          path="/dashboard/income-details"
          component={pages.IncomeDetails}
        /> */}
        <InstructorRoute
          {...props}
          exact
          path="/dashboard/earns-incomes"
          component={pages.IncomeDetails}
        />
        <InstructorRoute
          {...props}
          exact
          path="/dashboard/my-courses/lectures"
          component={pages.Lectures}
        />
        <InstructorRoute
          {...props}
          exact
          path="/dashboard/subscribers"
          component={pages.Subscribers}
        />
        <InstructorRoute
          {...props}
          exact
          path="/dashboard/create-course"
          component={pages.CreateCourse}
        />
      </Layout>
      {/* <Route component={pages.NotFound} path="/not-found" /> */}
      <Redirect to="/home" />
    </Switch>
  );
};
export default AppRouter;

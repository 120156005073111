import i18n from "../configs/i18n";
import { storage } from "./storage.adapter";
class SystemStatus {
  getAuthStatus() {
    try {
      return JSON.parse(storage.useLocalStorage().getItem("authenticated"));
    } catch (err) {
      return false;
    }
  }

  getSuspendStatus() {
    try {
      return JSON.parse(storage.useCookie().getItem("suspend"));
    } catch (err) {
      return false;
    }
  }

  saveAuthData(token: string, redirect) {
    storage.useLocalStorage().setItem("authenticated", true);
    storage.useLocalStorage().setItem("accessToken", token);

    redirect && window.location.replace("/home");
  }
  saveGuestData(token: string) {
    storage.useLocalStorage().setItem("accessToken", token);
    storage.useLocalStorage().setItem("guestAccountCreated", true);
  }
  getGuestAccountStatus() {
    // console.log(storage.useLocalStorage().getItem("guestAccountCreated"));

    return storage.useLocalStorage().getItem("guestAccountCreated");
  }
  getUserInfo() {
    try {
      return JSON.parse(storage.useLocalStorage().getItem("user"));
    } catch (err) {
      return false;
    }
  }
  discardAuthData(refresh?: boolean) {
    storage.useLocalStorage().setItem("accessToken", "");
    storage.useLocalStorage().setItem("authenticated", false);
    storage.useLocalStorage().setItem("user", null);

    if (refresh) {
      window.location.replace("/");
    }
  }

  getToken() {
    return storage.useLocalStorage().getItem("accessToken");
  }

  suspendSystem() {
    storage.useCookie().setItem("suspend", true);
    window.location.replace("/lockscreen");
  }
  desuspendSystem() {
    storage.useCookie().setItem("suspend", false);
    window.location.replace("/login");
  }

  getSystemLang(lang: "en" | "ar") {
    return storage.useLocalStorage().getItem("lang") === lang;
  }

  getActiveAccount = () => {
    const user = this.getUserInfo();

    try {
      for (let i of user?.permissions) {
        if (i.active) {
          return i;
        }
      }
    } catch (err) {
      // window.location.replace("/login");
    }
  };

  changeSystemLang(lang: string) {
    const body: any = document.querySelector("body");
    storage.useLocalStorage().setItem("lang", lang);
    if (lang === "ar") {
      i18n.changeLanguage("ar");
      body.classList.add("rtl");
      body.classList.remove("ltr");
    } else {
      body.classList.add("ltr");
      body.classList.remove("rtl");
      i18n.changeLanguage("en");
    }
    // // localStorage.setItem("lang", lang);
  }

  registerLastPage(location: any) {
    storage
      .useLocalStorage()
      .setItem("lastPage", `${location.pathname}${location.search}`);
  }
  getLastPage(): string {
    return storage.useLocalStorage().getItem("lastPage");
  }
}
export const systemStatus = new SystemStatus();

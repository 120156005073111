import React from "react";
import arLogo from "./img/capsula.png";
import enLogo from "../../../assets/logos/en-logo.png";
import { store } from "../../../redux";
import { systemStatus } from "../../../helpers/auth.status";
const Logo = (props) => {
  return (
    <>
      {/* <Link to="/home" className="navbar-brand "> */}
      <div className="the-logo flex justify-center">
        <img
          // src={enLogo}
          src={systemStatus.getSystemLang("ar") ? arLogo : enLogo}
          alt="Capsula"
          style={{
            width: props.width || "50%",
            height: props.height || "50%",
            objectFit: "contain",
          }}
        />
      </div>
      {/* </Link> */}
    </>
  );
};
export default Logo;

import { applyMiddleware, createStore, compose } from "redux";
import thunk from "redux-thunk";

import { combineReducers } from "redux";
// import { Session } from "./session.reducer";
import { Instructor } from "./reducers/instructor.reducer";
import { InstructorLecture } from "./reducers/instructorLecture.reducer";
import { InstructorCapsula } from "./reducers/instructorCapsula.reducer";
import { InstructorGetCourse } from "./reducers/instructorGet.reducer";
// import { Courses } from "./reducers/Courses.reducer";
// import { Payment } from "./reducers/Payment.reducer";
// import { Users } from "./reducers/user.reducer";
import Categories from "./categories.duck";
import Session from "./session.duck";
import Courses from "./courses.duck";
import Payment from "./payments.duck";
import Users from "./user.duck";

// RTK API CUSTOM QUERIES
import { paymentAPI } from "../services/payment";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { configureStore } from "@reduxjs/toolkit";
import { coursesAPI } from "../services/courses";
// const Reducers = combineReducers({
// Session,
// Instructor,
// InstructorLecture,
// InstructorCapsula,
// InstructorGetCourse,
// Courses,
// Payment,
//   [paymentAPI.reducerPath]: paymentAPI.reducer,
// Users,
// Categories,
// });
const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [paymentAPI.reducerPath]: paymentAPI.reducer,
    [coursesAPI.reducerPath]: coursesAPI.reducer,
    Session,
    Instructor,
    InstructorLecture,
    InstructorCapsula,
    InstructorGetCourse,
    Courses,
    Payment,
    Users,
    Categories,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(paymentAPI.middleware),
});

// const middleware = [thunk /**logger */];

// const store = createStore(Reducers, compose(applyMiddleware(...middleware)));

export { store };

setupListeners(store.dispatch);

import {
  GlobalServiceAdapter,
  ServiseAdapterCustomVersion,
} from "../helpers/services";
import notify from "../components/common/notification-system/notification";
import * as Types from "./types";
import { systemStatus } from "../helpers/auth.status";
import { storage } from "../helpers/storage.adapter";
import i18n from "../configs/i18n";

const ERRORS = "SESSION/ERRORS";
const LOADING = "SESSION/LOADING";
const ACCOUNT_VERIFIED = "SESSION/ACCOUNT_VERIFIED";
const STOP_LOADING = "SESSION/STOP_LOADING";
const VERIFICATION_SUCCEEDED = "SESSION/VERIFICATION_SUCCEEDED";
const OTP_SENT = "SESSION/OTP_SENT";
const OTP_ERROR = "SESSION/OTP_ERROR";

export default function Reducer(state = {}, { type, payload }) {
  switch (type) {
    case Types.LOADING: {
      return { ...state, loading: true };
    }
    case LOADING: {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }
    case STOP_LOADING: {
      return {
        ...state,
        loading: false,
        errors: null,
      };
    }
    case VERIFICATION_SUCCEEDED: {
      return {
        ...state,
        errors: null,
        loading: false,
        verificationSucceeded: true,
      };
    }
    case OTP_SENT: {
      return {
        ...state,
        loading: false,
        otpSent: true,
      };
    }
    case OTP_ERROR: {
      return {
        ...state,
        loading: false,
        errors:
          "invalid otp, please make sure you have received the opt in your email",
      };
    }
    case Types.LOGIN_SUCCESS: {
      return {
        ...state,
        authenticated: true,
        token: payload.token,
        errors: null,
        loading: false,
      };
    }
    case Types.LANG: {
      return {
        ...state,
        lang: payload,
        errors: null,
        loading: false,
      };
    }
    case Types.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        user: payload,
        errors: null,
        loading: false,
      };
    }
    case ACCOUNT_VERIFIED: {
      return {
        ...state,
        errors: null,
        loading: false,
        accountVerified: true,
        msg: payload,
      };
    }

    case Types.CLEAN_USER_SESSION_INFO: {
      return {
        ...state,
        // user: null,
        errors: null,
        loading: false,
      };
    }

    case Types.SIGNUP_SUCCESS: {
      return {
        ...state,
        authenticated: false,
        errors: null,
        user: payload,
        loading: false,
      };
    }

    case Types.GET_AGE_GROUPS: {
      return {
        ...state,
        ageGroups: payload,
        loading: false,
      };
    }
    case Types.GET_CURRENCY: {
      return {
        ...state,
        currency: payload,
        loading: false,
      };
    }
    case Types.LOGOUT_SUCCESS: {
      return {
        ...state,
        authenticated: false,
        token: null,
        errors: null,
        user: null,
        loading: false,
      };
    }
    case Types.CLEAN_SESSION_ERRORS: {
      return {
        ...state,
        errors: null,
        loading: false,
      };
    }

    case Types.SET_USER_ROLE_SUCCESS: {
      return {
        ...state,
        user: payload,
        errors: null,
        loading: false,
      };
    }
    case Types.UPDATE_USER_INFO_SUCCESS: {
      return {
        ...state,
        updateInfo: payload,
        errors: null,
        loading: false,
      };
    }
    case Types.UPDATE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        updatePassword: payload,
        errors: null,
        loading: false,
      };
    }
    case ERRORS: {
      return {
        ...state,
        errors: payload,
        loading: false,
      };
    }
    case Types.REMEMBER_ME_SUCCESS: {
      return {
        ...state,
        rememberMe: payload,
        errors: null,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
}

// signup redux action, this action will perform signup api request uses api proxies
const signupInstructor = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    // send api request through proxy and store response
    const response = await GlobalServiceAdapter.sendData(
      "auth/instructors/register",
      data
    );
    window.location.replace("/login");
    // if request is succeed dispatch receive data
    dispatch({ type: Types.SIGNUP_SUCCESS, payload: response?.data });
  } catch (err) {
    // if request is failed dispatch error
    console.log(err?.message);
  }
};

const signupStudent = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    // send api request through proxy and store response
    const payload = await ServiseAdapterCustomVersion.sendData(
      "v2/auth/users/register",
      data
    );

    systemStatus.saveAuthData(payload?.token, true);

    getUserInfo()(dispatch);
  } catch (err) {
    // if request is failed dispatch error

    dispatch({ type: ERRORS, payload: err });
  }
};

// login redux action, this action will perform login api request uses api proxies
const login = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    // send api request through proxy and store response
    const payload = await GlobalServiceAdapter.sendData("auth/login", data);
    // localStorage.setItem("user", JSON.stringify(payload.user));

    systemStatus.saveAuthData(payload?.token, true);

    getUserInfo()(dispatch);
    // dispatch({ type: Types.LOGIN_SUCCESS, payload: payload?.user });
  } catch (err) {
    console.log(err);

    // if request is failed dispatch error
    dispatch({ type: ERRORS, payload: err.message });
  }
};

// getting languages from localstorage
// export const languages = (data) => async (dispatch) => {
//   try {
//     dispatch({ type: Types.LANG, payload: data });
//   } catch (err) {
//     // if request is failed dispatch error
//     dispatch({ type: Types.LANG, payload: err.data });
//   }
// };

// get age groups redux action, this action will perform age groups api request uses api proxies
export const getAgeGroups = () => async (dispatch) => {
  try {
    // send api request through proxy and store response
    const response = await GlobalServiceAdapter.fetchData("users/ageGroups");
    // if request is succeed dispatch receive data
    dispatch({ type: Types.GET_AGE_GROUPS, payload: response?.data });
  } catch (err) {
    // if request is failed dispatch error
  }
};

// export const getCurrencies = () => async (dispatch) => {
//   try {
//     const response = await GlobalServiceAdapter.fetchData(
//       "users/availableCurrencies"
//     );
//     dispatch({ type: Types.GET_CURRENCY, payload: response.data });
//   } catch (err) {}
// };

const logout = () => (dispatch) => {
  dispatch({ type: Types.LOGOUT_SUCCESS });

  systemStatus.discardAuthData(true);
};

// remember me
const remember = (data) => (dispatch) => {
  try {
    dispatch({ type: Types.REMEMBER_ME_SUCCESS, payload: data });
  } catch (err) {
    dispatch({
      type: ERRORS,
      payload: "Remember Me : #error",
    });
  }
};

const getUserInfo = () => async (dispatch) => {
  // dispatch({type:Types.LOADING});
  try {
    // ...
    const response = await GlobalServiceAdapter.fetchData("users/userInfo");
    localStorage.setItem("user", JSON.stringify(response));

    dispatch({ type: Types.GET_USER_INFO_SUCCESS, payload: response });
  } catch (err) {
    // ...
    dispatch({ type: ERRORS, payload: err?.response?.data });
  }
};

// Updating user Image
const UpdateUserImage = (data) => async (dispatch) => {
  try {
    // ...
    await GlobalServiceAdapter.updateFiles("users/profileImage", data);

    getUserInfo()(dispatch);

    notify("نجاح", "تم تحديث البانات بنجاح", "success");
  } catch (err) {
    // ...
    dispatch({
      type: ERRORS,
      payload: err?.response?.data,
    });
    notify("خطأ", "حدث خطأ أثناء تحديث البانات", "danger");
  }
};

// Updating user INFORMATION
const UpdateUserInfo = (data) => async (dispatch) => {
  try {
    // ...
    const response = await GlobalServiceAdapter.updateData(
      "users/update",
      data
    );
    dispatch({
      type: Types.UPDATE_USER_INFO_SUCCESS,
      payload: response?.data,
    });
    notify("Succeeded", "Your information has been updatd ...", "success");
  } catch (err) {
    // ...
    dispatch({
      type: ERRORS,
      payload: err.response?.data,
    });
    notify("Failed", "informations not updated !", "danger");
  }
};

// Updating user PASSWORD
const UpdateUserPassword = (data) => async (dispatch) => {
  try {
    const response = await GlobalServiceAdapter.updateData(
      "users/Reset-Password",
      data
    );
    dispatch({
      type: Types.UPDATE_USER_PASSWORD_SUCCESS,
      payload: response?.data,
    });

    notify("Succeeded", "Password has been reset successfuly", "success");
  } catch (err) {
    // ...
    dispatch({
      type: ERRORS,
      payload: err.response?.data,
    });

    notify("Failed", "Try again with correct informations", "danger");
  }
};

const switchAccount = () => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapter.updateData(
      "users/switch-active-account",
      {}
    );

    storage.useLocalStorage().setItem("user", JSON.stringify(data));
    dispatch({ type: Types.GET_USER_INFO_SUCCESS, payload: data });
  } catch (err) {
    // ...
    dispatch({
      type: ERRORS,
      payload: err.response?.data,
    });
  }
};

const uploadSelectedCategories = (ids) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.sendData("users/select-interested-categories", {
      categories: ids,
    });

    getUserInfo()(dispatch);
  } catch (err) {
    // ...
    dispatch({
      type: ERRORS,
      payload: err.response?.data,
    });
  }
};

const getVerificationEmail = () => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapter.sendData(
      "auth/verification/send-email",
      {}
    );

    // getUserInfo()(dispatch);
    notify(i18n.t("success"), data, "success");
  } catch (err) {
    // ...
    dispatch({
      type: ERRORS,
      payload: err.response?.data,
    });
  }
};

const verifyEmail = (info) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    await GlobalServiceAdapter.sendData("auth/verification/verify-email", info);

    getUserInfo()(dispatch);

    dispatch({
      type: ACCOUNT_VERIFIED,
      payload: "your account has been verified successfully",
    });
  } catch (err) {
    // ...

    dispatch({
      type: ERRORS,
      payload: err.message,
    });
  }
};

const createGuestAccount = () => async (dispatch) => {
  if (systemStatus.getGuestAccountStatus()) return;

  try {
    const data = await ServiseAdapterCustomVersion.sendData(
      "v2/auth/create-guest-accounts",
      {}
    );
    console.log(systemStatus.getAuthStatus());

    if (!systemStatus.getAuthStatus()) {
      systemStatus.saveGuestData(data);
    }
  } catch (err) {}
};

const sendOtpToEmail = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await ServiseAdapterCustomVersion.sendData(
      "v1/auth/password-reset/send-otp",
      data
    );
    dispatch({ type: OTP_SENT });
  } catch (err) {
    dispatch({ type: ERRORS, payload: err?.message });
  }
};
const verifyOtp = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    const payload = await ServiseAdapterCustomVersion.sendData(
      "v1/auth/password-reset/verify-otp",
      data
    );
    dispatch({ type: VERIFICATION_SUCCEEDED });
    systemStatus.saveAuthData(payload?.token, false);
    window.location.replace("/account/reset-password");
  } catch (err) {
    console.log(err);

    dispatch({ type: OTP_ERROR });
  }
};
const resetPassword = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await ServiseAdapterCustomVersion.sendData(
      "v1/auth/password-reset/reset",
      data
    );
    notify(
      i18n.t("success"),
      "your password has been reset successfully",
      "success"
    );

    dispatch({ type: STOP_LOADING });
    setTimeout(() => {
      window.location.replace("/home");
    }, 2500);
  } catch (err) {
    dispatch({ type: ERRORS, payload: err.message });
  }
};

const loginToVerify = (data) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const payload = await GlobalServiceAdapter.sendData("auth/login", data);

    console.log("outter", systemStatus.getAuthStatus());

    systemStatus.saveAuthData(payload?.token, false);

    setTimeout(() => {
      dispatch({ type: ACCOUNT_VERIFIED });
    }, 1000);
  } catch (err) {
    dispatch({ type: ERRORS, payload: err.message });
  }
};

const verifyAccount = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await GlobalServiceAdapter.sendData("auth/verification/verify-email", data);
    dispatch({ type: STOP_LOADING });
  } catch (err) {
    console.log(err);

    dispatch({ type: ERRORS, payload: err.message });
  }
};

const joinGroup = (data) => async (dispatch) => {
  dispatch({ type: LOADING });

  try {
    await GlobalServiceAdapter.sendData("/users/joinInstitute/", data);

    notify(
      i18n.t("success"),
      "you have joined, now you can access the partner courses in your profile",
      "success"
    );

    dispatch({ type: STOP_LOADING });
  } catch (err) {
    notify(i18n.t("error"), "cannot join the partner now", "danger");
    dispatch({ type: ERRORS, payload: err?.message });
  }
};

export const sessionActions = {
  UpdateUserPassword,
  UpdateUserInfo,
  getUserInfo,
  UpdateUserImage,
  getAgeGroups,
  logout,
  remember,
  signupStudent,
  login,
  signupInstructor,
  switchAccount,
  uploadSelectedCategories,
  getVerificationEmail,
  verifyEmail,
  createGuestAccount,
  sendOtpToEmail,
  verifyOtp,
  resetPassword,
  loginToVerify,
  verifyAccount,
  joinGroup,
};

import Cookie from "universal-cookie";

interface Istorage {
  getItem: Function;
  removeItem: Function;
  setItem: Function;
}

class StorageCreator {
  private storageInstance: Istorage;

  private createStorage = (storage, get, set, remove) => {
    this.storageInstance = {
      getItem: (item) => {
        return storage[get](item);
      },
      setItem: (item, value) => {
        return storage[set](item, value);
      },
      removeItem: (item) => {
        return storage[remove](item);
      },
    };

    return this.storageInstance;
  };

  public useLocalStorage = () => {
    return this.createStorage(localStorage, "getItem", "setItem", "removeItem");
  };

  public useCookie = () => {
    return this.createStorage(new Cookie(), "get", "set", "remove");
  };
}

export const storage = new StorageCreator();

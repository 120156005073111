import notify from "../components/common/notification-system/notification";
import i18n from "../configs/i18n";
import {
  GlobalServiceAdapter,
  ServiseAdapterCustomVersion,
} from "../helpers/services";

const GET_CARDS = "PAYMENTS/GET_CARDS";
const GET_COURSES_EARNS = "PAYMENTS/GET_COURSES_EARNS";
const GET_AVAILABLE_CURRENCIES = "PAYMENTS/GET_AVAILABLE_CURRENCIES";
const PURCHES_SUCCEED = "PAYMENTS/PURCHES_SUCCEED";
const PAYMENT_METHODS = "PAYMENTS/PAYMENT_METHODS";
const MANUAL_PAYMENT_SUCCEED = "PAYMENTS/MANUAL_PAYMENT_SUCCEED";
const MBOK_PURCHES_SUCCEED = "PAYMENTS/MBOK_PURCHES_SUCCEED";
const LOADING = "PAYMENTS/LOADING";
const ERRORS = "PAYMENTS/ERRORS";

export default function (state = { loading: true }, { type, payload }) {
  switch (type) {
    case LOADING: {
      return {
        ...state,
        loading: true,
        errors: null,
      };
    }
    case GET_CARDS: {
      return { ...state, loading: false, cards: payload };
    }
    case GET_COURSES_EARNS: {
      return { ...state, loading: false, earns: payload };
    }
    case MBOK_PURCHES_SUCCEED: {
      return { ...state, loading: false, enrollment: payload };
    }
    case GET_AVAILABLE_CURRENCIES: {
      return { ...state, loading: false, currencies: payload };
    }
    case PURCHES_SUCCEED: {
      return { ...state, loading: false, purchase: true };
    }
    case PAYMENT_METHODS: {
      return { ...state, loading: false, paymentMethods: payload };
    }
    case MANUAL_PAYMENT_SUCCEED: {
      return { ...state, loading: false, manualPurchase: true };
    }
    case ERRORS: {
      return { ...state, loading: false, purchase: false };
    }
    default: {
      return state;
    }
  }
}

const getCards = () => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapter.fetchData(
      `payments/billingAccounts`
    );

    dispatch({ type: GET_CARDS, payload: data });
  } catch (err) {}
};
const updateCard = (id, info) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.updateData(
      `payments/billingAccount/${id}`,
      info
    );
    getCards()(dispatch);
  } catch (err) {}
};
const getCoursesEarns = () => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapter.fetchData(
      `payments/instructorEarnings`
    );
    // console.log(data);
    dispatch({ type: GET_COURSES_EARNS, payload: data });
  } catch (err) {}
};
const getCurrency = () => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapter.fetchData(
      `users/availableCurrencies`
    );
    // console.log(data);
    dispatch({ type: GET_AVAILABLE_CURRENCIES, payload: data });
  } catch (err) {}
};
const addBillingAccount = (info) => async (dispatch) => {
  try {
    await GlobalServiceAdapter.sendData(`payments/BillingAccount/Add`, info);
    // console.log(data);
    getCards()(dispatch);
    // dispatch({ type: GET_AVAILABLE_CURRENCIES, payload: data });
  } catch (err) {}
};
const enrollCourse = (courseId, info) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await GlobalServiceAdapter.sendData(
      `courses/${courseId}/enroll`,
      info
    );

    dispatch({ type: MBOK_PURCHES_SUCCEED, payload: data });
  } catch (err) {
    dispatch({ type: ERRORS });
  }
};
const enrollFreeCourse = (id) => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapter.sendData(
      `courses/${id}/enroll`,
      {}
    );

    window.location.replace(`/classroom?uid=${id}&autoPlay=true&play=1`);

    dispatch({ type: PURCHES_SUCCEED, payload: data });
  } catch (err) {
    dispatch({ type: ERRORS });
  }
};

const getAvailableAccounts = () => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapter.fetchData(
      `payments/availableBillingAccounts`
    );

    dispatch({ type: GET_CARDS, payload: data });
  } catch (err) {
    dispatch({ type: ERRORS });
  }
};

const getAvailablePaymentMethods = () => async (dispatch) => {
  try {
    const data = await GlobalServiceAdapter.fetchData(
      `payments/payment-methods`
    );
    // console.log(data);

    dispatch({ type: PAYMENT_METHODS, payload: data });
  } catch (err) {
    dispatch({ type: ERRORS });
  }
};

const manualEnrollment = (info) => async (dispatch) => {
  dispatch({ type: LOADING });
  try {
    const data = await ServiseAdapterCustomVersion.sendFiles(
      `v2/courses/manual-payment-request`,
      info
    );

    // getCards()(dispatch);
    dispatch({ type: MANUAL_PAYMENT_SUCCEED, payload: data });
  } catch (err) {
    dispatch({ type: ERRORS });
  }
};
export const paymentActions = {
  getCards,
  updateCard,
  getCoursesEarns,
  getCurrency,
  addBillingAccount,
  enrollCourse,
  getAvailableAccounts,
  getAvailablePaymentMethods,
  manualEnrollment,
  enrollFreeCourse,
};

import { Redirect } from "react-router-dom";
import { systemStatus } from "../helpers/auth.status";
import { PrivateRoute } from "./private-route";

export const InstructorRoute = (props) => {
  if (systemStatus.getActiveAccount()?.scope === "instructor") {
    return <PrivateRoute exact path={props.path} component={props.component} />;
  } else {
    return <Redirect to={`/home`} />;
  }
};

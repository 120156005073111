import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import AppRouter from "./AppRoutes";
import { connect } from "react-redux";
import ReactNotifications from "react-notifications-component";
import { Component, useState } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import Navbar from "../components/layout/Navbar2";
import Footer from "../components/common/footer/Footer";
import { systemStatus } from "../helpers/auth.status";
import { sessionActions } from "../redux/session.duck";
import { storage } from "../helpers/storage.adapter";
import { useForm } from "react-hook-form";
import { GlobalServiceAdapter } from "../helpers/services";
import { extractQuery } from "../util/extracter";
class App extends Component<any, any> {
  state = {
    fullScreenPages: false,
  };
  async componentDidMount() {
    this.props.getUserInfo();
    this.onRouteChanged();

    if (storage.useLocalStorage().getItem("lang") === "null") {
      systemStatus.changeSystemLang("ar");
    }
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
      systemStatus.registerLastPage(prevProps.location);
    }
  }

  onRouteChanged = () => {
    const lang = localStorage.getItem("lang");
    window.scrollTo(0, 0);

    systemStatus.changeSystemLang(lang);

    const fullScreenPages = [
      "/login",
      "/register",
      "/classroom/" + this.props?.location?.pathname?.split("/")?.[2],
      "/account/verify_email",
      "/account/restore-password",
      "/account/verify-account",
      "/account/reset-password",
    ];

    if (fullScreenPages.includes(this.props?.location?.pathname)) {
      this.setState({ fullScreenPages: true });
    } else {
      this.setState({ fullScreenPages: false });
    }
  };

  render() {
    return (
      <div className="App">
        <ReactNotifications />
        {!this.state?.fullScreenPages && <Navbar />}
        <AppRouter />
        {!this.state?.fullScreenPages && <Footer />}

        <PartnerWindow />
      </div>
    );
  }
}
const mapStateToProps = (state: any) => {
  return {
    user: state.Session.user,
  };
};
export default connect(mapStateToProps, { ...sessionActions })(
  withTranslation()(withRouter(App))
);

function PartnerWindow() {
  const partnersForm = useForm();

  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [joined, setJoined] = useState(false);

  const onJoinPartner = async (data) => {
    setErrors(null);
    setLoading(true);
    try {
      await GlobalServiceAdapter.sendData("/users/joinInstitute/", data);

      setLoading(false);
      setJoined(true);
    } catch (err) {
      setLoading(false);
      setErrors(err);
    }
  };

  const query: any = extractQuery(useLocation().search);

  return (
    <div
      id="partner-window"
      className="w-full hidden h-[100vh] flex items-center justify-center fixed z-50 top-0 left-0 bg-[#4a4a4a1a] "
    >
      {systemStatus.getAuthStatus() ? (
        <div className="bg-white relative overflow-hidden rounded-[5px] w-[100%] sm:w-[80%] md:w-[500px] p-10">
          <div
            onClick={() => {
              document.getElementById("partner-window").classList.add("hidden");
            }}
            className=" absolute top-[20px] cursor-pointer right-[20px] z-50"
          >
            <i className="fa fa-times text-gray-300 text-[12px]"></i>
          </div>
          {loading && (
            <div className="absolute flex items-center justify-center top-0 left-0 bg-white  w-full h-full">
              <Trans>loading</Trans>
            </div>
          )}
          {errors && (
            <div className="absolute text-red-500 flex items-center justify-center  top-0 left-0 bg-white  w-full h-full">
              <div className="">
                <div className="text-center text-[19px]">
                  <Trans>{errors?.message}</Trans>
                </div>
                <div className="flex items-center justify-center mt-2">
                  <div
                    onClick={() => setErrors(null)}
                    className="btn btn-primary my-2 px-3 mx-1"
                  >
                    <Trans>try-again</Trans>
                  </div>
                </div>
              </div>
            </div>
          )}
          {joined ? (
            <div>
              <div className="text-center">
                <i className="fa text-green-400 text-[50px] fa-check-circle"></i>
              </div>
              <div className="text-center mt-3">
                <Trans>you-have-joined</Trans>
              </div>
              <div className="text-center mt-3">
                <Link to="#" className="btn btn-light my-2 px-3 mx-1">
                  <Trans>partners</Trans>
                </Link>
                <Link to="/profile" className="btn btn-primary my-2 px-3 mx-1">
                  <Trans>profile</Trans>
                </Link>
              </div>
            </div>
          ) : (
            <div>
              <div className="text-center text-[22px] font-bold">
                <Trans>join-partner</Trans>
              </div>
              <form
                className=" mt-4"
                onSubmit={partnersForm.handleSubmit(onJoinPartner)}
              >
                <div className="flex justify-between items-center">
                  <span>
                    <Trans>enter-partner-code</Trans>
                  </span>

                  <input
                    className=" bg-gray-200 py-1 px-2"
                    type="text"
                    placeholder="code"
                    {...partnersForm.register("code", { required: true })}
                  />
                </div>
                <div className="flex justify-center mt-4">
                  <button className="btn btn-primary my-2 px-3 mx-2">
                    <Trans>join</Trans>
                  </button>
                  <Link
                    to={`/payments/courses?cid=${query?.uid}`}
                    className="btn btn-secondary my-2 px-3 mx-2"
                    onClick={() =>
                      document
                        .getElementById("partner-window")
                        .classList.add("hidden")
                    }
                  >
                    <Trans>purchase</Trans>
                  </Link>
                </div>
              </form>
            </div>
          )}
        </div>
      ) : (
        <div className="bg-white h-[250px] relative overflow-hidden rounded-[5px] w-[100%] sm:w-[80%] md:w-[500px] p-10">
          <div
            onClick={() => {
              document.getElementById("partner-window").classList.add("hidden");
            }}
            className=" absolute top-[20px] cursor-pointer right-[20px] z-50"
          >
            <i className="fa fa-times text-gray-300 text-[12px]"></i>
          </div>
          <div className="absolute flex items-center justify-center top-0 left-0 bg-white  w-full h-full">
            <Trans>login-first</Trans>
          </div>
        </div>
      )}
    </div>
  );
}

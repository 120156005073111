import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Link } from "react-router-dom";
import { configs } from "../../configs";
import i18n from "../../configs/i18n";
import { systemStatus } from "../../helpers/auth.status";
import { GlobalServiceAdapter } from "../../helpers/services";
import Logo from "../common/navbar/logo";
// import "./setup.css";
export default function Navbar(props: any) {
  const [toggle, setToggle] = useState(false);
  const [partners, setPartners] = useState(null);

  const user = systemStatus.getUserInfo();

  useEffect(() => {
    getPartners();
  }, []);

  const getPartners = async () => {
    try {
      const data = await GlobalServiceAdapter.fetchData("institutes");

      setPartners(data);
      // console.log({ data });
    } catch (err) {
      console.log(err);
    }
  };

  // console.log();

  return (
    <div className="relative z-20 border-b-2 border-gray-100">
      <div className="md:px-5 md:h-[70px] transition-all duration-100 ">
        <div className="px-3 py-3 md:mx-5 md:flex  md:justify-between items-center h-full">
          <div className="flex justify-between items-center">
            <Link to="/home" className="">
              <Logo width="100px" />
            </Link>
            <div className="md:hidden">
              <div
                onClick={() => setToggle(!toggle)}
                className="w-[30px]  cursor-pointer "
              >
                <div className="w-full h-[3px] bg-gray-400 rouned-[5px] mb-1"></div>
                <div className="w-full h-[3px] bg-gray-400 rouned-[5px] mb-1"></div>
                <div className="w-full h-[3px] bg-gray-400 rouned-[5px]"></div>
              </div>
            </div>
          </div>
          <div className={`md:flex ${!toggle && "hidden"} items-center gap-3`}>
            <Link to="/home" className="flex py-1  md:py-0 ">
              <i className="far fa-home px-2 text-sm"></i>{" "}
              <span>
                <Trans>home</Trans>{" "}
              </span>
            </Link>
            {!systemStatus.getAuthStatus() ? (
              <Link to="login" className="flex py-1 md:py-0">
                <i className="far fa-sign-in px-2 text-sm"></i>{" "}
                <span>
                  {" "}
                  <Trans>login</Trans>{" "}
                </span>
              </Link>
            ) : null}
            {systemStatus.getAuthStatus() &&
            systemStatus.getUserInfo()?.accountType === "instructor" ? (
              <Link to="dashboard/my-courses" className="flex py-1 md:py-0">
                <i className="far fa-tachometer px-2 text-sm"></i>{" "}
                <span>
                  {" "}
                  <Trans>dashboard</Trans>{" "}
                </span>
              </Link>
            ) : null}

            {/* parteners toggle */}
            {true ? (
              <div
                onClick={() => {
                  const partner = document?.getElementById("toggle-partner");
                  partner?.classList.toggle("hidden");
                  try {
                    const profile = document.getElementById("toggle-profile");
                    const notif = document.getElementById("toggle-notif");
                    notif.classList.add("hidden");
                    profile.classList.add("hidden");
                  } catch (err) {}
                }}
                className="flex cursor-pointer py-1 md:py-0 relative"
              >
                <i className="far fa-users px-2 text-sm"></i>{" "}
                <span>
                  {" "}
                  <Trans>partners</Trans>{" "}
                </span>
                <i className="far fa-angle-down px-2 text-sm"></i>
                <div
                  id="toggle-partner"
                  className="bg-white p-2 border-2 z-0 border-gray-50 w-[300px] absolute left-0 top-[40px]  hidden"
                >
                  {partners?.map((item) => {
                    return (
                      <Link
                        key={item?.id}
                        to={`/partners/${item?.id}?pr=${item.instituteName}`}
                        className="flex items-center px-3 py-2 transition-all duration-100 hover:bg-gray-100"
                      >
                        <span>{item?.instituteName}</span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            ) : null}

            {/* profile toggle */}
            {systemStatus.getAuthStatus() ? (
              <ProfileMenuComponent
                idSelector={"toggle-profile-mobile"}
                user
                configs
                appendClassNames={"cursor-pointer flex md:hidden py-1 md:py-0"}
              />
            ) : null}
            {systemStatus.getAuthStatus() ? (
              <Link to="/settings" className="flex md:hidden py-1 md:py-0">
                <i className="far fa-cog px-2 text-sm"></i>{" "}
                <span>
                  <Trans>settings</Trans>{" "}
                </span>
              </Link>
            ) : null}
            {systemStatus.getAuthStatus() ? (
              <Link
                onClick={() => systemStatus.discardAuthData()}
                to="login"
                className="flex md:hidden py-1 md:py-0"
              >
                <i className="far fa-sign-out px-2 text-sm"></i>{" "}
                <span>
                  <Trans>logout</Trans>{" "}
                </span>
              </Link>
            ) : null}

            {/* Show "Profile Link" when User is loged in */}
            {systemStatus.getAuthStatus() ? (
              <ProfileMenuComponent
                idSelector={"toggle-profile-desktop"}
                configs={configs}
                user={user}
                appendClassNames={"cursor-pointer relative hidden md:flex"}
              />
            ) : null}

            <div className="flex md:pt-0 pt-2 items-center">
              {/* Notification Bell */}
              <div
                onClick={() => {
                  const notif = document.getElementById("toggle-notif");
                  const partner = document.getElementById("toggle-partner");

                  partner.classList.add("hidden");
                  notif.classList.toggle("hidden");
                  const profileMobile = document.getElementById(
                    "toggle-profile-mobile"
                  );
                  const profileWindow = document.getElementById(
                    "toggle-profile-window"
                  );
                  profileMobile?.classList.add("hidden");
                  profileWindow?.classList.add("hidden");
                }}
                className="flex ml-3 items-center relative justify-center cursor-pointer w-[40px] h-[40px] rounded-full bg-gray-200"
              >
                <i className="far fa-bell text-md "></i>
              </div>

              {/* Language Icon */}
              <div
                onClick={() =>
                  systemStatus.changeSystemLang(
                    systemStatus.getSystemLang("ar") ? "en" : "ar"
                  )
                }
                className="flex ml-3 items-center justify-center cursor-pointer w-[40px] h-[40px] rounded-full bg-gray-200"
              >
                <span>
                  {i18n.language === "ar" ? (
                    <Trans>en</Trans>
                  ) : (
                    <Trans>ar</Trans>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="toggle-notif"
        className={`bg-white border-2 z-0 border-gray-50 w-[300px] h-[90vh] flex items-center justify-center fixed ${
          systemStatus.getSystemLang("ar") ? "left-0" : "right-0"
        }  top-[70px]  hidden`}
      >
        <div className="flex justify-center items-center px-3 py-3 transition-all duration-100 hover:bg-gray-100 ">
          no notification found
        </div>
      </div>
    </div>
  );
}

// Side Component
// Profile Menu
const ProfileMenuComponent = ({ user, configs, ...props }) => {
  return (
    <div
      onClick={() => {
        const profile = document.getElementById(props.idSelector);
        profile.classList.toggle("hidden");
        const partner = document.getElementById("toggle-partner");

        partner.classList.add("hidden");
        const notif = document.getElementById("toggle-notif");
        notif.classList.add("hidden");
      }}
      className={props.appendClassNames}
    >
      <i className="far fa-user px-2 text-sm"></i>
      <span>
        <Trans>profile</Trans>{" "}
      </span>
      <i className="far fa-angle-down px-2 text-sm"></i>

      {/* Profile tab */}
      <div
        id={props.idSelector}
        className="bg-white border-2 z-0 border-gray-50 w-[300px] absolute left-0 top-[40px]  hidden"
      >
        <Link
          to="/profile"
          className="flex items-center border-b-2 border-gray-50 p-3"
        >
          <div className="flex rounded-full overflow-hidden items-center justify-center cursor-pointer w-[50px] h-[50px] rounded-full bg-gray-200">
            <img src={`${configs?.api}/${user?.profileImage}`} alt="" />
          </div>
          <div className="px-3">
            <span>
              {user?.firstName
                ? user?.firstName + " " + user?.lastName
                : user?.username}
            </span>
          </div>
        </Link>
        <div className="flex items-center px-3 py-2 transition-all duration-100 hover:bg-gray-100 border-b-2 border-gray-50">
          <Link to="/profile?filter=enrolled" className="flex">
            <i className="far fa-money-bill px-2 text-sm"></i>{" "}
            <span>
              {" "}
              <Trans>my-subscription</Trans>{" "}
            </span>{" "}
          </Link>
        </div>

        <div className="flex items-center px-3 py-2 transition-all duration-100 hover:bg-gray-100 border-b-2 border-gray-50">
          <Link to="/settings" className="flex">
            <i className="far fa-cog px-2 text-sm"></i>{" "}
            <span>
              {" "}
              <Trans>settings</Trans>{" "}
            </span>{" "}
          </Link>
        </div>

        <div
          onClick={() => systemStatus.discardAuthData(true)}
          className="flex items-center px-3 py-2 transition-all duration-100 hover:bg-gray-100 "
        >
          <Link to="#" className="flex">
            <i className="far fa-sign-out px-2 text-sm"></i>{" "}
            <span>
              <Trans>logout</Trans>{" "}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
};
